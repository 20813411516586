import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Twitter from "../assets/icons/twitter.svg";
import LinkedIn from "../assets/icons/linkedin-in.svg";
import Facebook from "../assets/icons/facebook-f.svg";
import { format } from 'date-fns'
import Layout from '../components/layout'

export default function Blog({ data }) {

  const post = data.markdownRemark

  return (
    <Layout>

        <Helmet>
        <title>{post.frontmatter.title} - Blog</title>
        <meta name="description" content={post.frontmatter.excerpt} />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:url" content={ `https://kevinchoppin.dev/blog${post.fields.slug}` } />
        <meta property="og:image" content={`https://kevinchoppin.dev${post.frontmatter.img.publicURL}`} />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="667" />
        <meta property="og:description" content={post.frontmatter.excerpt} />
        <meta property="og:site_name" content="Kevin Choppin - Freelance Web Developer" />
        <meta name="twitter:site" content="@kevchoppin" />
        <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <div className="blog-container">
            <div className="blog__article">
                <h1>{post.frontmatter.title}</h1>
                <time dateTime={post.frontmatter.date}>{format(new Date(post.frontmatter.date), 'do MMMM, yyyy')}</time>
                <img src={post.frontmatter.img.publicURL} alt="" />
                <div className="blog__article-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                <div className="social-widget">
                    <h2>Share it on social</h2>
                    <div className="social-widget__links">
                        <button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.frontmatter.title +' '+ window.location.href)}`,'sharer','toolbar=0,status=0,width=600,height=460')} className="_twitter" aria-label="Share on Twitter"><Twitter /></button>
                        <button onClick={() => window.open(`https://www.linkedin.com/shareArticle?source=&title=${encodeURIComponent(post.frontmatter.title)}&summary=&mini=true&url=${encodeURIComponent(window.location.href)}`,'sharer','toolbar=0,status=0,width=550,height=550')} className="_linkedin" aria-label="Share on LinkedIn"><LinkedIn /></button>
                        <button onClick={() => window.open(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`,'sharer','toolbar=0,status=0,width=548,height=325')} className="_facebook" aria-label="Share on Facebook"><Facebook /></button>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        excerpt
        img {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`